html {
  min-height: calc(100vw + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

img {
  display: inline-block;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  z-index: -1;
}

body {
  min-width: 100vw;
  min-height: 100vh;
  padding: env(safe-area-inset);
}

@supports (padding: env(safe-area-inset)) {
  body {
    padding-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
  }
}